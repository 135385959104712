input {
  box-sizing: border-box;
}

.DateInput_input {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #7c7c7c;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  font-size: 1rem;
}

.DateInput_input__focused {
  border-color: #1A80C8;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #1a80c8;
  border-color: #1A80C8;
}

.CalendarDay__selected_span {
  background: #6CBCF5;
  border: 1px double #53B1F5;
  color: #ffffff;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background: #1a80c8;
  border: 1px double #1A80C8;
  color: #ffffff;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #6CBCF5;
  border: 1px double #53B1F5;
  color: #ffffff;
}